import { default as activatenhXeqIO9EgMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/auth/activate.vue?macro=true";
import { default as indexFfyYxpMeAuMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/auth/index.vue?macro=true";
import { default as restoretfLdd5uDQ3Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/auth/organisations/restore.vue?macro=true";
import { default as register_45complete1XQ1BsZFIBMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/auth/register-complete.vue?macro=true";
import { default as registerQCuwObrWDCMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/auth/register.vue?macro=true";
import { default as _91token_93MyhAphQRrIMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/boards/[token].vue?macro=true";
import { default as indexPtJoeiVBWbMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/buzzer/index.vue?macro=true";
import { default as _91id_93q5B6RPNwX3Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/dashboards/[id].vue?macro=true";
import { default as index10ynFjviXhMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/dashboards/index.vue?macro=true";
import { default as _91token_93NjszzhLL0FMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/dashboards/token/[token].vue?macro=true";
import { default as tokenDS8zRpdvl6Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/dashboards/token.vue?macro=true";
import { default as demo1g2sZXFpR0Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/demo.vue?macro=true";
import { default as indexEwC3brNLHeMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/events/index.vue?macro=true";
import { default as impressum0Purua9Po8Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/impressum.vue?macro=true";
import { default as indexU8jDJifFOpMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/index.vue?macro=true";
import { default as _91id_933M5x7oDuPtMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/leaderboards/[id].vue?macro=true";
import { default as indexoKfnfvkTVjMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/leaderboards/index.vue?macro=true";
import { default as _91id_93BCh3VFJ6FvMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/deals/[id].vue?macro=true";
import { default as indexoyOSnxbT9FMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/deals/index.vue?macro=true";
import { default as HSLtoRGBRBBQyGY4FuMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/devices/HSLtoRGB.js?macro=true";
import { default as RGBtoHSLft8PQDjWnqMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/devices/RGBtoHSL.js?macro=true";
import { default as _91id_93Zm3HmX2ueaMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/devices/[id].vue?macro=true";
import { default as _91id_93NkzJ0Bi6RgMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/devices/devicegroup/[id].vue?macro=true";
import { default as index6eT4eGtwCAMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/devices/index.vue?macro=true";
import { default as _91id_93Q4Yo6Ft2U7Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/eventTypes/[id].vue?macro=true";
import { default as index5fwKLefzo4Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/eventTypes/index.vue?macro=true";
import { default as _91id_93yb4RchxMj2Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/goals/[id].vue?macro=true";
import { default as indexXzgbsNTqWUMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/goals/index.vue?macro=true";
import { default as _91id_93Xc8eg4BUQAMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/groups/[id].vue?macro=true";
import { default as indexXeGfWYNXYgMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/groups/index.vue?macro=true";
import { default as indexcNfJUytSJUMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/settings/index.vue?macro=true";
import { default as indexn5ELzIr3qbMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/subscriptions/index.vue?macro=true";
import { default as _91id_93zyw3Blz1hGMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/users/[id].vue?macro=true";
import { default as indexINsUsYIrgyMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/users/index.vue?macro=true";
import { default as _91id_93EMe1TEFmdtMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/sounds/[id].vue?macro=true";
import { default as entriesP0xuoUgjKoMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/sounds/index/entries.vue?macro=true";
import { default as playlistssqcCSOPaHzMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/sounds/index/playlists.vue?macro=true";
import { default as indexmi8K8Ev99XMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/sounds/index.vue?macro=true";
import { default as indexKbAXAUel1KMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/devices/index.vue?macro=true";
import { default as _91id_93jcXWrR3m2RMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/firmware/[id].vue?macro=true";
import { default as indexX019DPseEZMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/firmware/index.vue?macro=true";
import { default as _91id_93nLRMnePT7UMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/hardware/[id].vue?macro=true";
import { default as indexlNBsPkeOhAMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/hardware/index.vue?macro=true";
import { default as indexDkU1K1F3UDMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/logs/index.vue?macro=true";
import { default as _91id_93d1QIMMXaAgMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/notificationTemplates/[id].vue?macro=true";
import { default as indexyLE6dgcNJeMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/notificationTemplates/index.vue?macro=true";
import { default as _91id_93VIcHWzIdscMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/organisations/[id].vue?macro=true";
import { default as indexDFQZoPmm3nMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/organisations/index.vue?macro=true";
import { default as _91id_93grn5CZHvxHMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/plans/[id].vue?macro=true";
import { default as indexSEzsV0QDVhMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/plans/index.vue?macro=true";
import { default as releaselogQZfoS3jX8mMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/releaselog.vue?macro=true";
import { default as toolsPefROjypWxMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/tools.vue?macro=true";
import { default as _91id_938hif6WBa7IMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/users/[id].vue?macro=true";
import { default as index1pkudDQTmjMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/users/index.vue?macro=true";
export default [
  {
    name: activatenhXeqIO9EgMeta?.name ?? "auth-activate",
    path: activatenhXeqIO9EgMeta?.path ?? "/auth/activate",
    meta: activatenhXeqIO9EgMeta || {},
    alias: activatenhXeqIO9EgMeta?.alias || [],
    redirect: activatenhXeqIO9EgMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/auth/activate.vue").then(m => m.default || m)
  },
  {
    name: indexFfyYxpMeAuMeta?.name ?? "auth",
    path: indexFfyYxpMeAuMeta?.path ?? "/auth",
    meta: indexFfyYxpMeAuMeta || {},
    alias: indexFfyYxpMeAuMeta?.alias || [],
    redirect: indexFfyYxpMeAuMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: restoretfLdd5uDQ3Meta?.name ?? "auth-organisations-restore",
    path: restoretfLdd5uDQ3Meta?.path ?? "/auth/organisations/restore",
    meta: restoretfLdd5uDQ3Meta || {},
    alias: restoretfLdd5uDQ3Meta?.alias || [],
    redirect: restoretfLdd5uDQ3Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/auth/organisations/restore.vue").then(m => m.default || m)
  },
  {
    name: register_45complete1XQ1BsZFIBMeta?.name ?? "auth-register-complete",
    path: register_45complete1XQ1BsZFIBMeta?.path ?? "/auth/register-complete",
    meta: register_45complete1XQ1BsZFIBMeta || {},
    alias: register_45complete1XQ1BsZFIBMeta?.alias || [],
    redirect: register_45complete1XQ1BsZFIBMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/auth/register-complete.vue").then(m => m.default || m)
  },
  {
    name: registerQCuwObrWDCMeta?.name ?? "auth-register",
    path: registerQCuwObrWDCMeta?.path ?? "/auth/register",
    meta: registerQCuwObrWDCMeta || {},
    alias: registerQCuwObrWDCMeta?.alias || [],
    redirect: registerQCuwObrWDCMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: _91token_93MyhAphQRrIMeta?.name ?? "boards-token",
    path: _91token_93MyhAphQRrIMeta?.path ?? "/boards/:token()",
    meta: _91token_93MyhAphQRrIMeta || {},
    alias: _91token_93MyhAphQRrIMeta?.alias || [],
    redirect: _91token_93MyhAphQRrIMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/boards/[token].vue").then(m => m.default || m)
  },
  {
    name: indexPtJoeiVBWbMeta?.name ?? "buzzer",
    path: indexPtJoeiVBWbMeta?.path ?? "/buzzer",
    meta: indexPtJoeiVBWbMeta || {},
    alias: indexPtJoeiVBWbMeta?.alias || [],
    redirect: indexPtJoeiVBWbMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/buzzer/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93q5B6RPNwX3Meta?.name ?? "dashboards-id",
    path: _91id_93q5B6RPNwX3Meta?.path ?? "/dashboards/:id()",
    meta: _91id_93q5B6RPNwX3Meta || {},
    alias: _91id_93q5B6RPNwX3Meta?.alias || [],
    redirect: _91id_93q5B6RPNwX3Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/dashboards/[id].vue").then(m => m.default || m)
  },
  {
    name: index10ynFjviXhMeta?.name ?? "dashboards",
    path: index10ynFjviXhMeta?.path ?? "/dashboards",
    meta: index10ynFjviXhMeta || {},
    alias: index10ynFjviXhMeta?.alias || [],
    redirect: index10ynFjviXhMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/dashboards/index.vue").then(m => m.default || m)
  },
  {
    name: tokenDS8zRpdvl6Meta?.name ?? "dashboards-token",
    path: tokenDS8zRpdvl6Meta?.path ?? "/dashboards/token",
    children: [
  {
    name: _91token_93NjszzhLL0FMeta?.name ?? "dashboards-token-token",
    path: _91token_93NjszzhLL0FMeta?.path ?? ":token()",
    meta: _91token_93NjszzhLL0FMeta || {},
    alias: _91token_93NjszzhLL0FMeta?.alias || [],
    redirect: _91token_93NjszzhLL0FMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/dashboards/token/[token].vue").then(m => m.default || m)
  }
],
    meta: tokenDS8zRpdvl6Meta || {},
    alias: tokenDS8zRpdvl6Meta?.alias || [],
    redirect: tokenDS8zRpdvl6Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/dashboards/token.vue").then(m => m.default || m)
  },
  {
    name: demo1g2sZXFpR0Meta?.name ?? "demo",
    path: demo1g2sZXFpR0Meta?.path ?? "/demo",
    meta: demo1g2sZXFpR0Meta || {},
    alias: demo1g2sZXFpR0Meta?.alias || [],
    redirect: demo1g2sZXFpR0Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: indexEwC3brNLHeMeta?.name ?? "events",
    path: indexEwC3brNLHeMeta?.path ?? "/events",
    meta: indexEwC3brNLHeMeta || {},
    alias: indexEwC3brNLHeMeta?.alias || [],
    redirect: indexEwC3brNLHeMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: impressum0Purua9Po8Meta?.name ?? "impressum",
    path: impressum0Purua9Po8Meta?.path ?? "/impressum",
    meta: impressum0Purua9Po8Meta || {},
    alias: impressum0Purua9Po8Meta?.alias || [],
    redirect: impressum0Purua9Po8Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexU8jDJifFOpMeta?.name ?? "index",
    path: indexU8jDJifFOpMeta?.path ?? "/",
    meta: indexU8jDJifFOpMeta || {},
    alias: indexU8jDJifFOpMeta?.alias || [],
    redirect: indexU8jDJifFOpMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_933M5x7oDuPtMeta?.name ?? "leaderboards-id",
    path: _91id_933M5x7oDuPtMeta?.path ?? "/leaderboards/:id()",
    meta: _91id_933M5x7oDuPtMeta || {},
    alias: _91id_933M5x7oDuPtMeta?.alias || [],
    redirect: _91id_933M5x7oDuPtMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/leaderboards/[id].vue").then(m => m.default || m)
  },
  {
    name: indexoKfnfvkTVjMeta?.name ?? "leaderboards",
    path: indexoKfnfvkTVjMeta?.path ?? "/leaderboards",
    meta: indexoKfnfvkTVjMeta || {},
    alias: indexoKfnfvkTVjMeta?.alias || [],
    redirect: indexoKfnfvkTVjMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/leaderboards/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93BCh3VFJ6FvMeta?.name ?? "organisation-deals-id",
    path: _91id_93BCh3VFJ6FvMeta?.path ?? "/organisation/deals/:id()",
    meta: _91id_93BCh3VFJ6FvMeta || {},
    alias: _91id_93BCh3VFJ6FvMeta?.alias || [],
    redirect: _91id_93BCh3VFJ6FvMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/deals/[id].vue").then(m => m.default || m)
  },
  {
    name: indexoyOSnxbT9FMeta?.name ?? "organisation-deals",
    path: indexoyOSnxbT9FMeta?.path ?? "/organisation/deals",
    meta: indexoyOSnxbT9FMeta || {},
    alias: indexoyOSnxbT9FMeta?.alias || [],
    redirect: indexoyOSnxbT9FMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/deals/index.vue").then(m => m.default || m)
  },
  {
    name: HSLtoRGBRBBQyGY4FuMeta?.name ?? "organisation-devices-HSLtoRGB",
    path: HSLtoRGBRBBQyGY4FuMeta?.path ?? "/organisation/devices/HSLtoRGB",
    meta: HSLtoRGBRBBQyGY4FuMeta || {},
    alias: HSLtoRGBRBBQyGY4FuMeta?.alias || [],
    redirect: HSLtoRGBRBBQyGY4FuMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/devices/HSLtoRGB.js").then(m => m.default || m)
  },
  {
    name: RGBtoHSLft8PQDjWnqMeta?.name ?? "organisation-devices-RGBtoHSL",
    path: RGBtoHSLft8PQDjWnqMeta?.path ?? "/organisation/devices/RGBtoHSL",
    meta: RGBtoHSLft8PQDjWnqMeta || {},
    alias: RGBtoHSLft8PQDjWnqMeta?.alias || [],
    redirect: RGBtoHSLft8PQDjWnqMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/devices/RGBtoHSL.js").then(m => m.default || m)
  },
  {
    name: _91id_93Zm3HmX2ueaMeta?.name ?? "organisation-devices-id",
    path: _91id_93Zm3HmX2ueaMeta?.path ?? "/organisation/devices/:id()",
    meta: _91id_93Zm3HmX2ueaMeta || {},
    alias: _91id_93Zm3HmX2ueaMeta?.alias || [],
    redirect: _91id_93Zm3HmX2ueaMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/devices/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93NkzJ0Bi6RgMeta?.name ?? "organisation-devices-devicegroup-id",
    path: _91id_93NkzJ0Bi6RgMeta?.path ?? "/organisation/devices/devicegroup/:id()",
    meta: _91id_93NkzJ0Bi6RgMeta || {},
    alias: _91id_93NkzJ0Bi6RgMeta?.alias || [],
    redirect: _91id_93NkzJ0Bi6RgMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/devices/devicegroup/[id].vue").then(m => m.default || m)
  },
  {
    name: index6eT4eGtwCAMeta?.name ?? "organisation-devices",
    path: index6eT4eGtwCAMeta?.path ?? "/organisation/devices",
    meta: index6eT4eGtwCAMeta || {},
    alias: index6eT4eGtwCAMeta?.alias || [],
    redirect: index6eT4eGtwCAMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/devices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Q4Yo6Ft2U7Meta?.name ?? "organisation-eventTypes-id",
    path: _91id_93Q4Yo6Ft2U7Meta?.path ?? "/organisation/eventTypes/:id()",
    meta: _91id_93Q4Yo6Ft2U7Meta || {},
    alias: _91id_93Q4Yo6Ft2U7Meta?.alias || [],
    redirect: _91id_93Q4Yo6Ft2U7Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/eventTypes/[id].vue").then(m => m.default || m)
  },
  {
    name: index5fwKLefzo4Meta?.name ?? "organisation-eventTypes",
    path: index5fwKLefzo4Meta?.path ?? "/organisation/eventTypes",
    meta: index5fwKLefzo4Meta || {},
    alias: index5fwKLefzo4Meta?.alias || [],
    redirect: index5fwKLefzo4Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/eventTypes/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yb4RchxMj2Meta?.name ?? "organisation-goals-id",
    path: _91id_93yb4RchxMj2Meta?.path ?? "/organisation/goals/:id()",
    meta: _91id_93yb4RchxMj2Meta || {},
    alias: _91id_93yb4RchxMj2Meta?.alias || [],
    redirect: _91id_93yb4RchxMj2Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/goals/[id].vue").then(m => m.default || m)
  },
  {
    name: indexXzgbsNTqWUMeta?.name ?? "organisation-goals",
    path: indexXzgbsNTqWUMeta?.path ?? "/organisation/goals",
    meta: indexXzgbsNTqWUMeta || {},
    alias: indexXzgbsNTqWUMeta?.alias || [],
    redirect: indexXzgbsNTqWUMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/goals/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Xc8eg4BUQAMeta?.name ?? "organisation-groups-id",
    path: _91id_93Xc8eg4BUQAMeta?.path ?? "/organisation/groups/:id()",
    meta: _91id_93Xc8eg4BUQAMeta || {},
    alias: _91id_93Xc8eg4BUQAMeta?.alias || [],
    redirect: _91id_93Xc8eg4BUQAMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/groups/[id].vue").then(m => m.default || m)
  },
  {
    name: indexXeGfWYNXYgMeta?.name ?? "organisation-groups",
    path: indexXeGfWYNXYgMeta?.path ?? "/organisation/groups",
    meta: indexXeGfWYNXYgMeta || {},
    alias: indexXeGfWYNXYgMeta?.alias || [],
    redirect: indexXeGfWYNXYgMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/groups/index.vue").then(m => m.default || m)
  },
  {
    name: indexcNfJUytSJUMeta?.name ?? "organisation-settings",
    path: indexcNfJUytSJUMeta?.path ?? "/organisation/settings",
    meta: indexcNfJUytSJUMeta || {},
    alias: indexcNfJUytSJUMeta?.alias || [],
    redirect: indexcNfJUytSJUMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexn5ELzIr3qbMeta?.name ?? "organisation-subscriptions",
    path: indexn5ELzIr3qbMeta?.path ?? "/organisation/subscriptions",
    meta: indexn5ELzIr3qbMeta || {},
    alias: indexn5ELzIr3qbMeta?.alias || [],
    redirect: indexn5ELzIr3qbMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93zyw3Blz1hGMeta?.name ?? "organisation-users-id",
    path: _91id_93zyw3Blz1hGMeta?.path ?? "/organisation/users/:id()",
    meta: _91id_93zyw3Blz1hGMeta || {},
    alias: _91id_93zyw3Blz1hGMeta?.alias || [],
    redirect: _91id_93zyw3Blz1hGMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexINsUsYIrgyMeta?.name ?? "organisation-users",
    path: indexINsUsYIrgyMeta?.path ?? "/organisation/users",
    meta: indexINsUsYIrgyMeta || {},
    alias: indexINsUsYIrgyMeta?.alias || [],
    redirect: indexINsUsYIrgyMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/organisation/users/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93EMe1TEFmdtMeta?.name ?? "sounds-id",
    path: _91id_93EMe1TEFmdtMeta?.path ?? "/sounds/:id()",
    meta: _91id_93EMe1TEFmdtMeta || {},
    alias: _91id_93EMe1TEFmdtMeta?.alias || [],
    redirect: _91id_93EMe1TEFmdtMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/sounds/[id].vue").then(m => m.default || m)
  },
  {
    name: indexmi8K8Ev99XMeta?.name ?? "sounds",
    path: indexmi8K8Ev99XMeta?.path ?? "/sounds",
    children: [
  {
    name: entriesP0xuoUgjKoMeta?.name ?? "sounds-index-entries",
    path: entriesP0xuoUgjKoMeta?.path ?? "entries",
    meta: entriesP0xuoUgjKoMeta || {},
    alias: entriesP0xuoUgjKoMeta?.alias || [],
    redirect: entriesP0xuoUgjKoMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/sounds/index/entries.vue").then(m => m.default || m)
  },
  {
    name: playlistssqcCSOPaHzMeta?.name ?? "sounds-index-playlists",
    path: playlistssqcCSOPaHzMeta?.path ?? "playlists",
    meta: playlistssqcCSOPaHzMeta || {},
    alias: playlistssqcCSOPaHzMeta?.alias || [],
    redirect: playlistssqcCSOPaHzMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/sounds/index/playlists.vue").then(m => m.default || m)
  }
],
    meta: indexmi8K8Ev99XMeta || {},
    alias: indexmi8K8Ev99XMeta?.alias || [],
    redirect: indexmi8K8Ev99XMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/sounds/index.vue").then(m => m.default || m)
  },
  {
    name: indexKbAXAUel1KMeta?.name ?? "system-devices",
    path: indexKbAXAUel1KMeta?.path ?? "/system/devices",
    meta: indexKbAXAUel1KMeta || {},
    alias: indexKbAXAUel1KMeta?.alias || [],
    redirect: indexKbAXAUel1KMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/devices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93jcXWrR3m2RMeta?.name ?? "system-firmware-id",
    path: _91id_93jcXWrR3m2RMeta?.path ?? "/system/firmware/:id()",
    meta: _91id_93jcXWrR3m2RMeta || {},
    alias: _91id_93jcXWrR3m2RMeta?.alias || [],
    redirect: _91id_93jcXWrR3m2RMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/firmware/[id].vue").then(m => m.default || m)
  },
  {
    name: indexX019DPseEZMeta?.name ?? "system-firmware",
    path: indexX019DPseEZMeta?.path ?? "/system/firmware",
    meta: indexX019DPseEZMeta || {},
    alias: indexX019DPseEZMeta?.alias || [],
    redirect: indexX019DPseEZMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/firmware/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nLRMnePT7UMeta?.name ?? "system-hardware-id",
    path: _91id_93nLRMnePT7UMeta?.path ?? "/system/hardware/:id()",
    meta: _91id_93nLRMnePT7UMeta || {},
    alias: _91id_93nLRMnePT7UMeta?.alias || [],
    redirect: _91id_93nLRMnePT7UMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/hardware/[id].vue").then(m => m.default || m)
  },
  {
    name: indexlNBsPkeOhAMeta?.name ?? "system-hardware",
    path: indexlNBsPkeOhAMeta?.path ?? "/system/hardware",
    meta: indexlNBsPkeOhAMeta || {},
    alias: indexlNBsPkeOhAMeta?.alias || [],
    redirect: indexlNBsPkeOhAMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/hardware/index.vue").then(m => m.default || m)
  },
  {
    name: indexDkU1K1F3UDMeta?.name ?? "system-logs",
    path: indexDkU1K1F3UDMeta?.path ?? "/system/logs",
    meta: indexDkU1K1F3UDMeta || {},
    alias: indexDkU1K1F3UDMeta?.alias || [],
    redirect: indexDkU1K1F3UDMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/logs/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93d1QIMMXaAgMeta?.name ?? "system-notificationTemplates-id",
    path: _91id_93d1QIMMXaAgMeta?.path ?? "/system/notificationTemplates/:id()",
    meta: _91id_93d1QIMMXaAgMeta || {},
    alias: _91id_93d1QIMMXaAgMeta?.alias || [],
    redirect: _91id_93d1QIMMXaAgMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/notificationTemplates/[id].vue").then(m => m.default || m)
  },
  {
    name: indexyLE6dgcNJeMeta?.name ?? "system-notificationTemplates",
    path: indexyLE6dgcNJeMeta?.path ?? "/system/notificationTemplates",
    meta: indexyLE6dgcNJeMeta || {},
    alias: indexyLE6dgcNJeMeta?.alias || [],
    redirect: indexyLE6dgcNJeMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/notificationTemplates/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93VIcHWzIdscMeta?.name ?? "system-organisations-id",
    path: _91id_93VIcHWzIdscMeta?.path ?? "/system/organisations/:id()",
    meta: _91id_93VIcHWzIdscMeta || {},
    alias: _91id_93VIcHWzIdscMeta?.alias || [],
    redirect: _91id_93VIcHWzIdscMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/organisations/[id].vue").then(m => m.default || m)
  },
  {
    name: indexDFQZoPmm3nMeta?.name ?? "system-organisations",
    path: indexDFQZoPmm3nMeta?.path ?? "/system/organisations",
    meta: indexDFQZoPmm3nMeta || {},
    alias: indexDFQZoPmm3nMeta?.alias || [],
    redirect: indexDFQZoPmm3nMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93grn5CZHvxHMeta?.name ?? "system-plans-id",
    path: _91id_93grn5CZHvxHMeta?.path ?? "/system/plans/:id()",
    meta: _91id_93grn5CZHvxHMeta || {},
    alias: _91id_93grn5CZHvxHMeta?.alias || [],
    redirect: _91id_93grn5CZHvxHMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/plans/[id].vue").then(m => m.default || m)
  },
  {
    name: indexSEzsV0QDVhMeta?.name ?? "system-plans",
    path: indexSEzsV0QDVhMeta?.path ?? "/system/plans",
    meta: indexSEzsV0QDVhMeta || {},
    alias: indexSEzsV0QDVhMeta?.alias || [],
    redirect: indexSEzsV0QDVhMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/plans/index.vue").then(m => m.default || m)
  },
  {
    name: releaselogQZfoS3jX8mMeta?.name ?? "system-releaselog",
    path: releaselogQZfoS3jX8mMeta?.path ?? "/system/releaselog",
    meta: releaselogQZfoS3jX8mMeta || {},
    alias: releaselogQZfoS3jX8mMeta?.alias || [],
    redirect: releaselogQZfoS3jX8mMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/releaselog.vue").then(m => m.default || m)
  },
  {
    name: toolsPefROjypWxMeta?.name ?? "system-tools",
    path: toolsPefROjypWxMeta?.path ?? "/system/tools",
    meta: toolsPefROjypWxMeta || {},
    alias: toolsPefROjypWxMeta?.alias || [],
    redirect: toolsPefROjypWxMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/tools.vue").then(m => m.default || m)
  },
  {
    name: _91id_938hif6WBa7IMeta?.name ?? "system-users-id",
    path: _91id_938hif6WBa7IMeta?.path ?? "/system/users/:id()",
    meta: _91id_938hif6WBa7IMeta || {},
    alias: _91id_938hif6WBa7IMeta?.alias || [],
    redirect: _91id_938hif6WBa7IMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/users/[id].vue").then(m => m.default || m)
  },
  {
    name: index1pkudDQTmjMeta?.name ?? "system-users",
    path: index1pkudDQTmjMeta?.path ?? "/system/users",
    meta: index1pkudDQTmjMeta || {},
    alias: index1pkudDQTmjMeta?.alias || [],
    redirect: index1pkudDQTmjMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241204160654/client/pages/system/users/index.vue").then(m => m.default || m)
  }
]